//==============================================================================
// Buybox Kit Customizer
//
// Allows user to customize a kit by selecting product options and add-ons.
//==============================================================================
import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { clsHelper } from '../../utilities/class-name-helper';
import { attrNames } from '../../utilities/global-constants';
import { convertProductAttributes, AttributesWithMetadata } from '../../utilities/data-attribute-parser';

import { ProductSearchResult, ProductSearchCriteria, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { searchByCriteriaAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { RichTextComponent, getCatalogId } from '@msdyn365-commerce/core';
import { format } from '@msdyn365-commerce-modules/utilities';

import { IBuyboxKitCustomizerData } from './buybox-kit-customizer.data';
import { IBuyboxKitCustomizerProps } from './buybox-kit-customizer.props.autogenerated';

//==============================================================================
// INTERFACES
//==============================================================================
// Formatted output data into strings to be sent to cart line and sales order line
export interface OutputData {
    electives: string;          // '003100b:190|004100b:179|005100b:190'
    substitutions: string;      // 'Science:30000|Math:40000'
    contents: string;           // 'Heritage Studies 4*|English 4 Online'
    showContentsText: string;   // 'Show Contents'
}

export interface CustomizerData {
    subject?: string;
    itemId?: string;
    productName?: string;
    productPrice?: string;
    defaultItemId?: string;
}

export interface CustomizerMap {
    subject: string;
    items: SubjectMap[];
    productName?: string;
    itemId?: string;
}

export interface SubjectMap {
    itemId: string;
    isDefault?: boolean;
    productName?: string;
    productPrice?: string;
}

export interface ContentsMap {
    itemId: string;
    productName: string;
    items: SubjectMap[];
}

//==============================================================================
// CLASS NAME UTILITY
//==============================================================================
const BASE_CLASS = 'buybox-kit-customizer';
const cls = (fragment?: string) => clsHelper(BASE_CLASS, fragment);

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * BuyboxKitCustomizer component
 * @extends {React.Component<IBuyboxKitCustomizerProps<IBuyboxKitCustomizerData>>}
 */
//==============================================================================
@observer
class BuyboxKitCustomizer extends React.Component<IBuyboxKitCustomizerProps<IBuyboxKitCustomizerData>> {
    //==========================================================================
    // VARIABLES
    //==========================================================================
    @observable private isOpen = false;
    private readonly kitOptions = {
        electives: attrNames.kitElectives,
        substitutions: attrNames.kitSubstitutions,
        contents: attrNames.kitContents
    };
    @observable private productLists: {[key: string]: (CustomizerMap | ContentsMap)[]};
    @observable private customizerData: {[key: string]: CustomizerData[]};

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================
    //------------------------------------------------------
    // Constructor
    //------------------------------------------------------
    constructor(props: IBuyboxKitCustomizerProps<IBuyboxKitCustomizerData>) {
        super(props);
        this.productLists = this._constructLists(this.kitOptions);
        this.customizerData = this._constructLists(this.kitOptions);
    }

    //------------------------------------------------------
    // Invoked immediately after component is mounted
    //------------------------------------------------------
    public async componentDidMount(): Promise<void> {
        await this._getProductList(this.kitOptions.electives, this._parseCustomizerData);
        await this._getProductList(this.kitOptions.substitutions, this._parseCustomizerData);
        await this._getProductList(this.kitOptions.contents, this._parseContentsData);

        // Update line attributes with customizer data - necessary to show contents even if customizations were not made
        this._setLineAttributes();
    }

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element | null {
        const { config, resources } = this.props;
        const {
            [this.kitOptions.electives]: electives,
            [this.kitOptions.substitutions]: substitutions
        } = this.productLists;

        // If product lists are empty, do not render module
        if (!electives?.length && !substitutions?.length) {
            return null;
        }

        return (
            <div className={classnames(BASE_CLASS, config.className)}>

                {/* Only return step label if set up in config */}
                {config.labelCustomizer &&
                    <div className={cls('step-heading')}>
                        {this._useKitSelector() && (
                            <span className={cls('step-number')}>
                                {resources.buyboxKitCustomizer_stepLabel} 3:
                            </span>
                        )}
                        <span className={cls('step-label')}>
                            {config.labelCustomizer}
                        </span>
                    </div>
                }

                <form className={cls('form')} onSubmit={event => this._resetCustomizer(event)}>

                    {/* Customizer collapsible box */}
                    <div className={classnames(cls('container'), {'hidden': !this.isOpen})}>

                        {/* Elective subjects */}
                        {electives &&
                            <div className={cls('electives')}>
                                {/* Elective options */}
                                {this._renderSubjects(electives as CustomizerMap[], this.kitOptions.electives, true)}
                            </div>
                        }

                        {/* Substitution subjects */}
                        {substitutions &&
                            <div className={cls('substitutions')}>
                                {/* Substitutions info header */}
                                <div className={cls('substitutions-header')}>
                                    <div className={cls('substitutions-heading')}>
                                        {format(resources.buyboxKitCustomizer_subsHeading, config.subsTotal)}
                                    </div>
                                    {config.subsText && <RichTextComponent text={config.subsText} className={cls('substitutions-text')} />}
                                    <div className={cls('substitutions-counter')}>
                                        <span className={cls('substitutions-counter-label')}>
                                            {resources.buyboxKitCustomizer_subsLabelRemaining}:
                                        </span>
                                        <span className={cls('substitutions-counter-number')}>
                                            {this._calculateSubstitutions(this.customizerData[this.kitOptions.substitutions])}
                                        </span>
                                    </div>
                                </div>
                                {/* Substitution options */}
                                {this._renderSubjects(substitutions as CustomizerMap[], this.kitOptions.substitutions, false)}
                            </div>
                        }

                    </div>

                    {/* Customizer buttons */}
                    <div className={cls('buttons')}>
                        {/* Opens customizer box, needs to remain a link to not be tied to form's submit */}
                        <a
                            className={classnames(cls('button'), 'customize', {'hidden': this.isOpen})}
                            role='button'
                            onClick={this._toggleSubstitutions}
                        >
                            {resources.buyboxKitCustomizer_subsButtonCustomize}
                        </a>
                        {/* Closes customizer box and resets all choices, needs to be a button to tie to form's default submit behavior */}
                        <button
                            className={classnames(cls('button'), 'reset', {'hidden': !this.isOpen})}
                            onClick={this._toggleSubstitutions}
                        >
                            {resources.buyboxKitCustomizer_subsButtonReset}
                        </button>
                    </div>

                </form>

            </div>
        );
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================
    //------------------------------------------------------
    // Render subject list
    //------------------------------------------------------
    private readonly _renderSubjects = (subjects: CustomizerMap[], option: string, optional: boolean): JSX.Element => {
        return (
            <div className={cls('subjects')}>
                {subjects.map((subject, index) => {
                    return (
                        <div className={cls('subject')} key={index}>
                            <div className={cls('subject-label')}>
                                {subject.subject}
                            </div>
                            <div className={cls('subject-options')}>
                                {this._renderSelect(subject, option, optional)}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    //------------------------------------------------------
    // Render subject select
    //------------------------------------------------------
    private readonly _renderSelect = (subject: CustomizerMap, option: string, optional: boolean): JSX.Element => {
        // Check if subject has been changed by finding corresponding object in customizer data
        const isChanged = !!this.customizerData[option].find(dataSubject => dataSubject.subject === subject.subject);
        // Disable select if substitutions available has reached 0 and if this select has not been changed
        const isDisabled = this._calculateSubstitutions(this.customizerData[option]) <= 0 ? !isChanged : false;
        return (
            <select
                className={cls('subject-select')}
                name={subject.subject}
                defaultValue={''}
                disabled={isDisabled}
                onChange={event => this._onSelectChange(event, option, subject)}
            >
                {/* Only add an opt out option when subject is optional */}
                {optional &&
                    <option className={cls('subject-option')} value=''>
                        {this.props.resources.buyboxKitCustomizer_electiveOptOut}
                    </option>
                }
                {subject.items.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            {this._renderOption(item, optional)}
                        </React.Fragment>
                    );
                })}
            </select>
        );
    };

    //------------------------------------------------------
    // Render each subject selector option
    //------------------------------------------------------
    private readonly _renderOption = (item: SubjectMap, optional: boolean): JSX.Element => {
        return (
            <option
                className={classnames(cls('subject-option'), {'default': item.isDefault})}
                // Only set value if required subject is not the default value - have to send
                // empty string, because undefined value will send the option text through instead
                value={!optional && item.isDefault ? '' : item.itemId}
            >
                {item.productName || item.itemId}
                {optional && !!item.productPrice &&
                    ` (${this.props.resources.buyboxKitCustomizer_electivePricePrefix} ${this.props.context.cultureFormatter.formatCurrency(item.productPrice)})`
                }
            </option>
        );
    };

    //------------------------------------------------------
    // Get product list with parsed properties
    //------------------------------------------------------
    private readonly _getProductList = async (attribute: string, parseFunction: Function): Promise<void> => {
        const convertedAttributes = this._getConvertedAttributes();
        const kitAttribute = convertedAttributes && convertedAttributes[attribute] as string;
        const parsedAttributes = kitAttribute && parseFunction(kitAttribute);

        const subjectItemIds = parsedAttributes && parsedAttributes.map((subject: CustomizerMap | ContentsMap) => subject.itemId);
        const subjectList = subjectItemIds && await this._getProducts(subjectItemIds);

        const subjectPromises = parsedAttributes && subjectList && parsedAttributes.map(async (subject: CustomizerMap | ContentsMap) => {
            const productItemIds = subject.items.map(item => item.itemId);
            const productList = await this._getProducts(productItemIds);

            const productResult = productList.map(item => {
                const itemId = item.ItemId!;
                const productName = item.Name;
                const productPrice = item.Price;
                return {itemId, productName, productPrice};
            });

            let subjectProductName = subject.productName;

            if (!subjectProductName && subject.itemId) {
                const subjectProduct = subjectList.find((product: ProductSearchResult) => product.ItemId === subject.itemId);
                subjectProductName = subjectProduct?.Name;
            }

            return {...subject, productName: subjectProductName, items: productResult};
        });

        // Wait for all subject requests to complete
        const subjectResult: (CustomizerMap | ContentsMap)[] = subjectPromises && await Promise.all(subjectPromises);

        this.productLists[attribute] = subjectResult || [];
    };

    //------------------------------------------------------
    // Get products from item IDs
    //------------------------------------------------------
    private readonly _getProducts = async (itemIds: string[]): Promise<ProductSearchResult[]> => {
        const { context } = this.props;

        const itemList = itemIds?.map(itemId => ({ ItemId: itemId }));

        // Build a ProductSearchCriteria object
        const criteria: ProductSearchCriteria = {
            ItemIds: itemList,
            Context: {
                ChannelId: context.request.apiSettings.channelId,
                CatalogId: getCatalogId(this.props.context.request)
            }
        };

        try {
            // Fetch search results
            const searchResults = await searchByCriteriaAsync({ callerContext: context.actionContext }, criteria);
            return searchResults;
        } catch (error) {
            if (context.telemetry) {
                context.telemetry.exception(error as Error);
                context.telemetry.debug('Unable to find products');
            }
        }

        return [];
    };

    //------------------------------------------------------
    // Parse kit customizer data into a friendlier format
    //
    // Ex input data: 'Heritage Studies:003100b,004100b*,
    //                 005100b|English:003200b,004200b*,
    //                 005200b'
    //------------------------------------------------------
    private readonly _parseCustomizerData = (customizerData: string): CustomizerMap[] => {
        return customizerData.split('|').map(property => {
            const splitData = property.split(':');
            return {
                subject: splitData[0],
                items: this._parseSubjectData(splitData[splitData.length - 1]) || [],
                itemId: this._findSubjectDefault(splitData[splitData.length - 1])
            };
        });
    };

    //------------------------------------------------------
    // Parse kit subject data into a friendlier format and
    // identify the default item
    //
    // Ex input data: '003100b,004100b*,005100b'
    //------------------------------------------------------
    private readonly _parseSubjectData = (subjectData: string): SubjectMap[] => {
        return subjectData.split(',').map(property => {
            // Item is default choice if includes *
            const isDefault = property.includes('*');

            // Remove * on default items when storing item ID
            let itemId = property;
            if (isDefault) {
                itemId = itemId.replace('*', '');
            }

            return {
                itemId,
                isDefault
            };
        });
    };

    //------------------------------------------------------
    // Find kit subject default item ID
    //
    // Ex input data: '003100b,004100b*,005100b'
    //------------------------------------------------------
    private readonly _findSubjectDefault = (subjectData: string): string | undefined => {
        const splitData = subjectData.split(',');
        const defaultItem = splitData.find(item => item.includes('*'))?.replace('*', '');
        return defaultItem;
    };

    //------------------------------------------------------
    // Parse kit contents data into a friendlier format
    //
    // Ex input data: 'American Republic DVD with Books
    //                 (4th ed.)|450635:515841,298380,
    //                 298364,434506,433656,418327|English 8
    //                 DVD with Books|523092:523093,…'
    //------------------------------------------------------
    private readonly _parseContentsData = (contentsData: string): ContentsMap[] => {
        const splitData = contentsData.split('|');
        const parsedData = [];

        for (let i = 0; i < splitData.length; i+=2) {
            const separatedItemData = splitData[i+1].split(':');

            parsedData.push({
                productName: splitData[i],
                itemId: separatedItemData[0],
                items: this._parseItemsData(separatedItemData[separatedItemData.length - 1]) || []
            });
        }

        return parsedData;
    };

    //------------------------------------------------------
    // Parse kit items data into a friendlier format
    //
    // Ex input data: '515841,298380,298364'
    //------------------------------------------------------
    private readonly _parseItemsData = (itemsData: string): SubjectMap[] => {
        return itemsData.split(',').map(property => {
            return {
                itemId: property
            };
        });
    };

    //------------------------------------------------------
    // Convert customizer data to formatted output before
    // being sent to the cart
    //
    // Ex output data: {
    //     electives: '003100b:190|004100b:179|005100b:190',
    //     substitutions: 'Science:30000|Math:40000',
    //     contents: 'Heritage Studies 4*|English 4 Online'
    // }
    //------------------------------------------------------
    private readonly _convertCustomizerData = (customizerData: {[key: string]: CustomizerData[]}): OutputData => {
        const electivesArray = customizerData[this.kitOptions.electives].map(elective => `${elective.itemId}:${elective.productPrice}`);
        const electives = electivesArray.join('|');
        const substitutionsArray = customizerData[this.kitOptions.substitutions].map(substitution => `${substitution.subject}:${substitution.itemId}`);
        const substitutions = substitutionsArray.join('|');
        const contents = this._getContentsData(customizerData);
        const showContentsText = this.props.resources.buyboxKitCustomizer_showContentsButton;
        return { electives, substitutions, contents, showContentsText };
    };

    //------------------------------------------------------
    // Gets kit content data, replaces substitutions, adds
    // electives, and finds each item's product name
    //------------------------------------------------------
    private readonly _getContentsData = (customizerData: {[key: string]: CustomizerData[]}): string => {
        const electives = customizerData[this.kitOptions.electives];
        const substitutions = customizerData[this.kitOptions.substitutions];
        const contents = this.productLists[this.kitOptions.contents] as ContentsMap[];

        // List out all kit content items
        const itemList = contents.map(item => {
            return {
                itemId: item.itemId,
                productName: item.productName
            };
        });

        // Find items in list that have substitutions
        const modifiedList = itemList.map(item => {
            const substitution = substitutions.find(subItem => subItem.defaultItemId === item.itemId);
            if (substitution) {
                return `${substitution.productName || substitution.itemId}*`;
            }
            return item.productName || item.itemId;
        });

        // Add electives to list
        electives.forEach(electiveItem => {
            const electiveString = electiveItem.productName || electiveItem.itemId;
            electiveString && modifiedList.unshift(`${electiveString}*`);
        });

        const finalList = modifiedList.join('|');           // Need to be stringified to be sent as line attributes

        return finalList;
    };

    //------------------------------------------------------
    // Get converted product attributes
    //------------------------------------------------------
    private readonly _getConvertedAttributes = (): AttributesWithMetadata | undefined => {
        const { data } = this.props;
        const productAttributes = data.productSpecificationData?.result;
        return productAttributes && convertProductAttributes(productAttributes);
    };

    //------------------------------------------------------
    // Construct each option list initializer based on array
    // of options
    //------------------------------------------------------
    private readonly _constructLists = (options: {[key: string]: string}): {[key: string]: []} => {
        const lists = {};
        Object.values(options).forEach(option => lists[option] = []);
        return lists;
    };

    //------------------------------------------------------
    // On select change
    //------------------------------------------------------
    private readonly _onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>, option: string, subject: CustomizerMap): void => {
        const currentItem = subject.items.find(item => item.itemId === event.currentTarget.value);
        // Check if the subject already exists in customizer data array
        const existingSubjectIndex = this.customizerData[option].findIndex(
          subjectItem => subjectItem.subject === event.currentTarget.name
        );
        // If the user selects the default option, remove the subject from customizerData
        if (event.currentTarget.value === subject.itemId) {
          if (existingSubjectIndex !== -1) {
            this.customizerData[option].splice(existingSubjectIndex, 1);
          }
        } else {
          // Update or add the subject in customizerData with the new selection
          if (existingSubjectIndex !== -1) {
            this.customizerData[option][existingSubjectIndex] = {
              subject: event.currentTarget.name,
              itemId: event.currentTarget.value,
              productName: currentItem?.productName,
              productPrice: currentItem?.productPrice,
              defaultItemId: subject.itemId
            };
          } else {
            this.customizerData[option].push({
              subject: event.currentTarget.name,
              itemId: event.currentTarget.value,
              productName: currentItem?.productName,
              productPrice: currentItem?.productPrice,
              defaultItemId: subject.itemId
            });
          }
        }
        // Update line attributes with customizer data
        this._setLineAttributes();
      };

    //------------------------------------------------------
    // Calculate remaining substitutions available
    //------------------------------------------------------
    private readonly _calculateSubstitutions = (substitutions?: CustomizerData[]): number => {
        const subsTotal = this.props.config.subsTotal;
        return substitutions ? (subsTotal - substitutions.length) : subsTotal;
    };

    //------------------------------------------------------
    // Reset customizer choices
    //------------------------------------------------------
    private readonly _resetCustomizer = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        // Reset form to revert select values to default
        event.currentTarget.reset();

        // Reconstruct empty customizer list to replace customizer data
        this.customizerData = this._constructLists(this.kitOptions);

        // Update line attributes with customizer data
        this._setLineAttributes();
    };

    //------------------------------------------------------
    // Toggle substitutions container
    //------------------------------------------------------
    private readonly _toggleSubstitutions = (): void => {
        this.isOpen = !this.isOpen;
    };

    //------------------------------------------------------
    // Set line attributes with output data
    //------------------------------------------------------
    private readonly _setLineAttributes = async (): Promise<void> => {
        const product: SimpleProduct = await this.props.data.product;
        const lineAttributes = product.ExtensionProperties?.find(attribute => attribute.Key === attrNames.lineAttributes);
        const outputData = this._convertCustomizerData(this.customizerData);
        const hasKitData = outputData?.contents || outputData?.electives || outputData?.substitutions;

        // If product has no kit data, do not set any line attributes
        if (!hasKitData) {
            return;
        }

        if (lineAttributes?.Value) {
            lineAttributes.Value[attrNames.kitConfigurator] = outputData;
        } else {
            // Forcing extra layer into extension properties for selected adding to cart line attributes
            product.ExtensionProperties?.push({
                Key: attrNames.lineAttributes,
                Value: {
                    [attrNames.kitConfigurator]: outputData
                }
            });
        }
    };

    //------------------------------------------------------
    // Determines whether kit selector will need to be used
    // by checking if kit formats attribute is populated
    //------------------------------------------------------
    private readonly _useKitSelector = (): boolean => {
        const convertedAttributes = this._getConvertedAttributes();
        const kitFormats = convertedAttributes && convertedAttributes[attrNames.kitFormats] as string;
        return kitFormats ? true : false;
    };
}

export default BuyboxKitCustomizer;
